import { Alert, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

// Styled component for the banner
const BannerAlert = styled(Alert)(({ theme }) => ({
  borderRadius: '0 0 15px 15px', // Curved at the bottom only
  padding: theme.spacing(1, 2),
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 500,
  boxShadow: theme.shadows[3],
  '& .MuiAlert-message': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

// Styled container with animation
const AnimatedContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  padding: 0,
  transition: 'transform 0.5s ease-in-out',
  transform: 'translateY(-100%)',
  '&.visible': {
    transform: 'translateY(0)',
  },
}));

/**
 * StudyReelExtensionBanner component
 *
 * This component displays a centered banner informing users that the StudyReel extension is required.
 */
export default function StudyReelExtensionBanner() {
  return (
    <AnimatedContainer className="visible">
      <BannerAlert
        severity="warning"
        variant="filled"
        sx={{
          maxWidth: 'fit-content',
        }}
      >
        StudyReel extension not detected. Log in to Chrome with your school account to get credit
        for learning.
      </BannerAlert>
    </AnimatedContainer>
  );
}
