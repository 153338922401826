import { API } from 'aws-amplify';
export interface SkillPlan {
  id?: number;
  subject: string;
  app: string;
  type: string;
  order: string | null;
  skill_id: string;
  skill_code?: string;
  permacode?: string;
  plan_grade: string;
  plan_grade_level: string;
  skill_name: string;
  supporting_skill_codes: string;
  source: string;
  invalidated_on: string;
}

export interface GetSkillPlanInput {
  subject: string;
  app: string;
  grade: string;
  source?: string;
  refreshKey?: number;
}

export async function getSkillPlan(input: GetSkillPlanInput): Promise<SkillPlan[]> {
  try {
    const apiName = process.env.NEXT_PUBLIC_ACADEMICS_API_NAME!;
    const path = `api/management/get-skill-plan`;
    const myInit = {
      headers: {},
      queryStringParameters: {
        subject: input.subject,
        app: input.app,
        grade: input.grade,
        source: input.source,
      },
    };
    return await API.get(apiName, path, myInit);
  } catch (error) {
    console.error('Error fetching skill plan:', error);
    throw error;
  }
}
