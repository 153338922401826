// i18n
import '@common/locales/i18n';

// scroll bar
import 'simplebar/dist/simplebar.css';

// lightbox
import 'react-image-lightbox/style.css';

import '@common/components/daily-activity/DailyActivityTable2h.css';

// slick-carousel
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/black-and-white.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';

import cookie from 'cookie';
import { ReactElement, ReactNode, useEffect, useState } from 'react';
// next
import { NextPage } from 'next';
import App, { AppContext, AppProps } from 'next/app';
import Head from 'next/head';
//
import { Provider as ReduxProvider } from 'react-redux';
// @mui
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// redux
import { store } from '@common/redux/store';
// utils
import { getSettings } from '@common/utils/getSettings';
// contexts
import { CollapseDrawerProvider } from '@common/contexts/CollapseDrawerContext';
import { DashboardSelectionProvider } from '@common/contexts/DashboardSelectionContext';
import { LayoutProvider } from '@common/contexts/LayoutContext';
import { SettingsProvider } from '@common/contexts/SettingsContext';
import { ModalUsageProvider } from 'common/contexts/ModalUsageContext';
// LayoutProvider/guard things
import AuthGuard from '../guards/AuthGuard';
import BlacklistedAttributeGuard from '../guards/BlacklistedAttributeGuard';
import GuestGuard from '../guards/GuestGuard';
import MobileGuard from '../guards/MobileGuard';
import PageGuard from '../guards/PageGuard';
import SiteConfigGuard from '../guards/SiteConfigGuard';
import { MENU_OPTIONS } from '../routes/AccountPopoverPaths';
import { Logo, logoHeight } from '../sections/Logo';
// StudyReel Extension Checker
import StudyReelExtensionChecker from '../components/StudyReelExtensionChecker';
// theme
import ThemeProvider from '@common/theme';
// components
import MotionLazyContainer from '@common/components/animate/MotionLazyContainer';
import { ChartStyle } from '@common/components/chart';
import NotistackProvider from '@common/components/NotistackProvider';
import ProgressBar from '@common/components/ProgressBar';
import ThemeSettings from '@common/components/settings';
import { SettingsValueProps } from '@common/components/settings/type';

import GoogleAnalytics from '@common/components/external-scripts/GoogleAnalytics';
import HelpDeskIcon from '@common/components/external-scripts/HelpDeskIcon';
import { AuthProvider } from '@common/contexts/JWTContextAmplify';
import { QueryClient, QueryClientProvider } from 'react-query';

import { UserFlagContextProvider } from '@common/contexts/UserFlagContext';

import dayjs from '@common/@types/dayjs-custom';
import { SiteConfig } from '@common/@types/siteConfig';
import InitializeReduxStore from '@common/redux/InitializeReduxStore';
import { getSiteConfig } from '@common/utils/amplify/getSiteConfig';
import { PageViewTracker } from '@common/utils/pageViewTracker';
import { LicenseInfo } from '@mui/x-license-pro';
import { NavConfig } from 'common/@types/nav';
import { getSiteStrings } from 'common/utils/getSiteStrings';
import '../react-calendar-styles.css';
import { getNavConfig } from '../routes/NavConfig';

// ----------------------------------------------------------------------

dayjs();

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

interface MyAppProps extends AppProps {
  settings: SettingsValueProps;
  Component: NextPageWithLayout;
}

const MyApp = (props: MyAppProps) => {
  const defaultSettings: SettingsValueProps = {
    themeMode: 'light',
    themeLayout: 'horizontal',
    themeStretch: false,
    themeContrast: 'default',
    themeDirection: 'ltr',
    themeColorPresets: 'default',
  };
  const { Component, pageProps, settings } = props;

  const getLayout = Component.getLayout ?? ((page) => page);
  const queryClient = new QueryClient();
  LicenseInfo.setLicenseKey(
    'e091347a2d927c5d94a00815f54ba32eTz02Mjk4MixFPTE3MTE2NTIwMzA1NjIsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
  );

  const [siteConfig, setSiteConfig] = useState<SiteConfig | null>(null);
  const [navConfig, setNavConfig] = useState<NavConfig[]>();
  useEffect(() => {
    (async function () {
      // Load SiteConfig
      const siteConfig = await getSiteConfig();
      setSiteConfig(siteConfig);

      // Load SiteStrings and dynamic NavConfig
      const siteStrings = await getSiteStrings();
      if (siteStrings) {
        const siteNavConfig = getNavConfig(siteStrings);
        setNavConfig(siteNavConfig);
      }
    })();
  }, []);

  return (
    <>
      <Head>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Head>

      <AuthProvider>
        <LayoutProvider
          GuestGuard={GuestGuard}
          MobileGuard={MobileGuard}
          PageGuard={PageGuard}
          AuthGuard={AuthGuard}
          BlacklistedAttributeGuard={BlacklistedAttributeGuard}
          accountPopoverOptions={MENU_OPTIONS}
          navConfig={navConfig}
          Logo={Logo}
          logoHeight={logoHeight}
        >
          <ReduxProvider store={store}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <CollapseDrawerProvider>
                <DashboardSelectionProvider>
                  <UserFlagContextProvider>
                    <SettingsProvider defaultSettings={settings || defaultSettings}>
                      <MotionLazyContainer>
                        <QueryClientProvider client={queryClient}>
                          <ThemeProvider themeName={siteConfig?.themeOptions?.name}>
                            <ThemeSettings>
                              <NotistackProvider>
                                <InitializeReduxStore>
                                  <SiteConfigGuard>
                                    <ModalUsageProvider>
                                      <ChartStyle />
                                      <ProgressBar />
                                      <HelpDeskIcon />
                                      <GoogleAnalytics />
                                      <PageViewTracker />
                                      <StudyReelExtensionChecker />
                                      {getLayout(<Component {...pageProps} />)}
                                    </ModalUsageProvider>
                                  </SiteConfigGuard>
                                </InitializeReduxStore>
                              </NotistackProvider>
                            </ThemeSettings>
                          </ThemeProvider>
                        </QueryClientProvider>
                      </MotionLazyContainer>
                    </SettingsProvider>
                  </UserFlagContextProvider>
                </DashboardSelectionProvider>
              </CollapseDrawerProvider>
            </LocalizationProvider>
          </ReduxProvider>
        </LayoutProvider>
      </AuthProvider>
      {/* <Analytics /> */}
    </>
  );
};

export default MyApp;

// ----------------------------------------------------------------------

MyApp.getInitialProps = async (context: AppContext) => {
  const appProps = await App.getInitialProps(context);

  const cookies = cookie.parse(
    context.ctx.req ? context.ctx.req.headers.cookie || '' : document.cookie
  );

  const settings = getSettings(cookies);

  return {
    ...appProps,
    settings,
  };
};
