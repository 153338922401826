import useAuth from '@common/hooks/useAuth';
import useSiteConfig from '@common/hooks/useSiteConfig';
import { Box } from '@mui/material';
import { Role } from 'common/@types/auth';
import { useCallback, useEffect, useState } from 'react';
import StudyReelExtensionBanner from './StudyReelExtensionBanner';

// Add Chrome extension API type declaration
declare global {
  interface Window {
    chrome?: {
      runtime?: {
        sendMessage: (extensionId: string, message: any, callback: (response: any) => void) => void;
        lastError?: {
          message: string;
        };
      };
    };
  }
}

// Extension ID from Chrome Web Store
const EXTENSION_ID = 'oelebjkghohmgbpkdpcblodalbhinkjj';
/**
 * StudyReelExtensionChecker component
 *
 * This component checks if the current user is a student/guide/admin and conditionally
 * renders the StudyReelExtensionBanner if the extension is not installed.
 */
export default function StudyReelExtensionChecker() {
  const { user, isAuthenticated } = useAuth();
  const siteConfig = useSiteConfig();
  const [showBanner, setShowBanner] = useState(false);

  // Check if the current campus is enabled for StudyReel extension
  const isCampusEnabled = useCallback(() => {
    if (!user?.alphaAuthAttributes?.campusId) return false;

    const userCampus = user.alphaAuthAttributes.campusId.padStart(3, '0');
    const enabledCampuses = siteConfig.data?.studyReelExtensionBannerCampus?.enabledCampuses || [];
    return enabledCampuses.includes(userCampus);
  }, [user, siteConfig.data]);

  // Function to check if the StudyReel extension is installed
  const checkExtension = useCallback(() => {
    try {
      if (!isAuthenticated || !user?.alphaAuthAttributes?.role) {
        setShowBanner(false);
        return;
      }

      // Skip if campus is not enabled
      if (!isCampusEnabled()) {
        setShowBanner(false);
        return;
      }

      if (user.alphaAuthAttributes.role !== Role.STUDENT) {
        setShowBanner(false);
        return;
      }
      // Check if runtime API is available (it should be in Chrome)
      if (typeof window?.chrome?.runtime?.sendMessage === 'function') {
        window.chrome.runtime.sendMessage(
          EXTENSION_ID,
          { message: 'version' },
          function (response) {
            if (window.chrome?.runtime?.lastError) {
              console.log('Extension not installed:', window.chrome.runtime.lastError.message);
              setShowBanner(true);
              return;
            }
            console.log('Response from extension:', response);
            if (response && response.status === 'ok') {
              // Extension is installed
              console.log('Extension is installed with version:', response.version);
              setShowBanner(false);
            } else {
              // Extension not installed or communication failed
              console.log('Extension response invalid:', response);
              setShowBanner(true);
            }
          }
        );
      } else {
        // Chrome API not available
        console.log('Chrome runtime API not available');
        setShowBanner(true);
      }
    } catch (e) {
      // Error occurred during communication
      console.error('Error checking extension:', e);
      setShowBanner(true);
    }
  }, [isCampusEnabled]);

  // Check for the extension periodically
  useEffect(() => {
    // Initial check with a small delay
    const initialCheckTimeout = setTimeout(() => {
      checkExtension();
    }, 2000);

    // Periodic check every minute
    const intervalId = setInterval(() => {
      checkExtension();
    }, 60 * 1000);

    // Clean up
    return () => {
      clearTimeout(initialCheckTimeout);
      clearInterval(intervalId);
    };
  }, [isAuthenticated, user, isCampusEnabled, checkExtension]);

  if (!showBanner) {
    return null;
  }

  // Position the banner at the top of the page
  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 9999,
        pointerEvents: 'none', // Allow clicks to pass through the container
        '& > *': {
          pointerEvents: 'auto', // But enable clicks on the actual banner
        },
      }}
    >
      <StudyReelExtensionBanner />
    </Box>
  );
}
