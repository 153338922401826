// Import the tracking library
import { AuthUser } from 'common/@types/auth';
import { merged_time_and_activity } from 'common/@types/prisma';
import {
  ampli,
  ClickActivityViewProperties,
  ClickCoachingProperties,
  ClickLearningLinkProperties,
  ClickLoginProperties,
  ClickMenuLinkProperties,
} from './ampli-sdk';

export type CampusType = ClickLoginProperties['dash'];
export type LoginType = ClickLoginProperties['login-method'];
export type ValidSubjectType = ClickCoachingProperties['subject'];
export type CurrentViewType = ClickActivityViewProperties['current-view'];
export type ClickedViewType = ClickActivityViewProperties['clicked-view'];
export type SubjectType = ClickActivityViewProperties['subject'];
export type MenuLinkType = ClickMenuLinkProperties['menu-link'];
export type MenuType = ClickMenuLinkProperties['menu'];
export type LearningLinkType = ClickLearningLinkProperties['learning-link'];

export function getCampusTypeByDomain(domain: string): CampusType {
  // prettier-ignore
  if (domain === 'alpha.school') {
    return 'Alpha';
  } else if (domain === '2hourlearning.com' || domain === '2hrlearning.com') {
    return 'Alpha Anywhere';
  } else if (domain === 'esports.school') {
    return 'Esports';
  } else if (domain === 'learnandearn.school') {
    return 'Learn and Earn';
  } else if (domain === 'havruta.school') { // AI-GEN - Cursor - GPT4
    return 'Havruta'; // AI-GEN - Cursor - GPT4
  } else if (domain === 'novatio.school') { 
    return 'Novatio'; 
  }

  console.error(`Unknown Campus by the domain ${domain}`);
  return domain as CampusType;
}

export class AmplitudeService {
  private readonly shouldTrack: boolean =
    process.env.NEXT_PUBLIC_ENV === process.env.NEXT_PUBLIC_AMPLITUDE_ENV_TO_TRACK;

  identify(user: AuthUser) {
    if (!this.shouldTrack) {
      return;
    }
    if (user == null) {
      ampli.identify(undefined, undefined, {
        user_id: undefined,
        device_id: undefined,
      });
      return;
    }

    ampli.identify(user.attributes.email, { Role: user.alphaAuthAttributes.role });
  }

  init(): void {
    if (!this.shouldTrack) {
      return;
    }
    if (process.env.NEXT_PUBLIC_AMPLITUDE_PROJECT_TOKEN === undefined) {
      return;
    }
    ampli.load({ client: { apiKey: process.env.NEXT_PUBLIC_AMPLITUDE_PROJECT_TOKEN as string } });
  }

  trackClickLogin(campus: CampusType, login_method: LoginType): void {
    if (!this.shouldTrack) {
      return;
    }

    ampli.clickLogin({
      dash: campus,
      'login-method': login_method,
    });
  }

  getAppMastery(mastered_levels: string): Array<{ app: string; value: number }> {
    if (typeof mastered_levels === 'string' && mastered_levels?.includes(':')) {
      const masteredLevels = (mastered_levels || '').split(',').map((level: string) => {
        const [app, value] = level.split(':');
        return { app, value: Number(value) };
      });
      return masteredLevels;
    }

    return [];
  }

  trackClickCoaching(campus: CampusType, learningSession: merged_time_and_activity): void {
    const round = (num: number) => Math.round(num * 100) / 100;

    if (!this.shouldTrack) {
      return;
    }

    const courses = learningSession.course?.split(',');
    const appMastery = this.getAppMastery(learningSession.mastered_levels);

    const isExclusive = appMastery.length === 1;

    const learningApp1 = appMastery.length > 0 ? appMastery[0].app : undefined;
    const learningApp1Mastery = appMastery.length > 0 ? appMastery[0].value : undefined;
    const learningApp1Level = courses && courses.length > 0 ? courses[0] : undefined;

    const learningApp2 = appMastery.length > 1 ? appMastery[1].app : undefined;
    const learningApp2Mastery = appMastery.length > 1 ? appMastery[1].value : undefined;
    const learningApp2Level = courses && courses.length > 1 ? courses[1] : undefined;

    const learningApp3 = appMastery.length > 2 ? appMastery[2].app : undefined;
    const learningApp3Mastery = appMastery.length > 2 ? appMastery[2].value : undefined;
    const learningApp3Level = courses && courses.length > 2 ? courses[2] : undefined;

    ampli.clickCoaching({
      dash: campus,
      subject: learningSession.subject as ValidSubjectType,
      'time-spent': learningSession.minutes_working ?? 0,
      accuracy: learningSession.perc_questions_correct
        ? round(learningSession.perc_questions_correct * 100)
        : undefined,
      'exclusive-app': isExclusive,
      'learning-app-1': learningApp1,
      'learning-app-1-level': learningApp1Level,
      'learning-app-1-mastered': learningApp1Mastery,
      'learning-app-2': learningApp2,
      'learning-app-2-level': learningApp2Level,
      'learning-app-2-mastered': learningApp2Mastery,
      'learning-app-3': learningApp3,
      'learning-app-3-level': learningApp3Level,
      'learning-app-3-mastered': learningApp3Mastery,
    });
  }

  trackClickActivityViewPage(
    campus: CampusType,
    current_view: CurrentViewType,
    clicked_view: ClickedViewType,
    empty_state?: boolean
  ) {
    if (!this.shouldTrack) {
      return;
    }

    const data = {
      dash: campus,
      'current-view': current_view,
      'clicked-view': clicked_view,
      'empty-state': empty_state,
    };
    ampli.clickActivityView(data);
  }

  trackClickActivityViewSession(
    campus: CampusType,
    current_view: CurrentViewType,
    clicked_date: string,
    subject?: SubjectType,
    is_2hr_session?: boolean
  ) {
    if (!this.shouldTrack) {
      return;
    }

    const data = {
      dash: campus,
      'empty-state': false,
      'current-view': current_view,
      'clicked-date': clicked_date,
      subject: subject,
      '2hr-subject': is_2hr_session,
    };

    ampli.clickActivityView(data);
  }

  trackClickMenuLink(
    campus: CampusType,
    menu_link: string,
    menu: MenuType,
    current_view?: CurrentViewType
  ) {
    if (!this.shouldTrack) {
      return;
    }

    const data = {
      dash: campus,
      menu: menu,
      'current-view': current_view,
      'menu-link': menu_link,
    };

    ampli.clickMenuLink(data as ClickMenuLinkProperties); // Chris added a grade property which we haven't set up yet
  }

  trackClickLearningLink(
    campus: CampusType,
    learning_link: LearningLinkType,
    skill_name: string,
    grade: string,
    source: string,
    subject: string,
    current_score: number,
    suggested_content?: string | null
  ) {
    if (!this.shouldTrack) {
      return;
    }
    const existsSuggestedContent =
      suggested_content !== undefined && // AI-GEN - Cursor - GPT4
      suggested_content !== null && // AI-GEN - Cursor - GPT4
      suggested_content.trim().length > 0; // AI-GEN - Cursor - GPT4
    const data: ClickLearningLinkProperties = {
      dash: campus,
      'learning-link': learning_link,
      score: current_score,
      'skill-name': skill_name,
      grade: grade as ClickLearningLinkProperties['grade'],
      source: source as ClickLearningLinkProperties['source'],
      subject: subject as ClickLearningLinkProperties['subject'],
      'suggested-content': existsSuggestedContent,
      'suggested-content-name': existsSuggestedContent ? suggested_content : undefined,
    };

    ampli.clickLearningLink(data);
  }

  trackPageViewed() {
    // AI-GEN - Cursor GPT4
    if (!this.shouldTrack) {
      // AI-GEN - Cursor GPT4
      return; // AI-GEN - Cursor GPT4
    } // AI-GEN - Cursor GPT4

    let domain = '';
    let path = '';
    let url = '';
    if (typeof window !== 'undefined') {
      domain = window.location.hostname;
      path = window.location.pathname;
      url = window.location.href; // AI-GEN - Copilot
    }

    ampli.amplitudePageViewed({
      '[Amplitude] Page Domain': domain,
      '[Amplitude] Page URL': url,
      '[Amplitude] Page Path': path,
    });
  }

  trackStartSession() {
    if (!this.shouldTrack) {
      // AI-GEN - Cursor GPT4
      return; // AI-GEN - Cursor GPT4
    } // AI-GEN - Cursor GPT4

    ampli.sessionStart();
  }
}

export const amplitudeService = new AmplitudeService();
